<template>
  <div class="onexfund-container">
    <Typography variant="pc-title-48 desktop-nav-only">{{ title }}</Typography>
    <Typography variant="pc-body-24 mobile-body-14 onexfund-description">
      {{ description }}
    </Typography>
    <div class="onexfund-fiat">
      <CryptoFiat
        :crypto="fundBalance"
        :hide-usd="true"
        digits-after-decimal="4"
        :iconSize="iconSize"
      />
    </div>
  </div>
</template>

<script>
import { keyStores, connect } from 'near-api-js'

export default {
  async created () {
    this.setIconSize()
    window.addEventListener('resize', this.setIconSize)
    await this.setupNearConnection()
    try {
      await this.getFundBalance()
    } catch (err) {
      console.log(err)
      this.addNotification('Error getting fund balance.', 'error')
    }
  },
  data () {
    return {
      iconSize: 11,
      fundBalance: null,
      title: '1XFUND.NEAR',
      description: 'We\'re proud to present 1XFUND.NEAR: an optional royalty funded by all digital drops on 1XRUN.Limited. Holders of the 1XRUN Genesis Card will work together with participating artists to select which charitable initiatives to support. No matter how far we travel forward, we\'ll never stop giving back.'
    }
  },
  methods: {
    async setupNearConnection () {
      const nearNetwork = process.env.VUE_APP_NEAR_NETWORK
      const config = {
        networkId: nearNetwork,
        keyStore: new keyStores.BrowserLocalStorageKeyStore(),
        nodeUrl: `https://rpc.${nearNetwork}.near.org`,
        walletUrl: `https://wallet.${nearNetwork}.near.org`,
        helperUrl: `https://helper.${nearNetwork}.near.org`,
        explorerUrl: `https://explorer.${nearNetwork}.near.org`
      }
      try {
        this.nearConnection = await connect(config)
      } catch (err) {
        this.addNotification('Error connecting to NEAR blockchain.', 'error')
      }
    },
    // TODO: This is really hacky... ideally the icon size is related to the font size in the component
    setIconSize () {
      this.iconSize = window.innerWidth * 0.08
      if (this.iconSize > 100) this.iconSize = 100
    },
    async getFundBalance () {
      const targetNetwork = process.env.VUE_APP_NEAR_NETWORK === 'mainnet' ? 'near' : process.env.VUE_APP_NEAR_NETWORK
      const fundAccount = await this.nearConnection.account(`1xfund.${targetNetwork}`)
      const balance = await fundAccount.getAccountBalance()
      this.fundBalance = balance.total
    }
  }
}
</script>

<style lang="scss" scoped>
.onexfund-container {
  margin: 40px 0;

  @include desktop {
    margin: 0 0 80px;
  }
}

.onexfund-description {
  margin-top: $space-ss;
}

.onexfund-fiat {
  position: relative;
  margin-top: 60px;
  display: flex;
  justify-content: right;
  font-size: 36px;

  @include desktop {
    margin-top: 80px;
    font-size: 140px;
  }

  @include media($bp-desktop-m) {
    font-size: 11vw;
  }

  ::v-deep svg {
    transform: translateY(-10%);
  }
}
</style>
